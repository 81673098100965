<template>
  <div class="my-project-requests-list">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Мои заявки</div>
        </div>
      </div>
      <paginated-list :count="collection.count"
                      :total-count="collection.totalCount"
                      :data-loading="collection.loading"
                      @update="loadData"
      >
        <template v-slot:loading>
          <div class="card">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <div class="card">
            <div class="card-body">
              <i>Нет данных</i>
            </div>
          </div>
        </template>
        <div v-for="item in collection.items"
             :class="{ 'border-info': isOnReview(item.status), 'border-success': isApproved(item.status), 'border-danger': isRejected(item.status) }"
             class="card"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-5">
                <div class="small text-muted d-none d-md-block">Проект</div>
                <div class="font-weight-bold">{{ item.name }}</div>
              </div>
              <div class="col-5 col-md-3 col-lg-2 mt-3 mt-md-0">
                <div class="small text-muted">Цель</div>
                <money :money="item.targetSum"/>
              </div>
              <div class="col-7 col-md-3 mt-3 mt-md-0">
                <div class="small text-muted">Статус</div>
                {{ statusLabel(item.status) }}
              </div>
              <div class="col-12 col-lg-2 mt-3 mt-lg-0 text-center">
                <router-link v-if="isOnFilling(item.status)"
                             :to="{ name: 'myProjectRequest', params: { uuid: item.uuid} }"
                             class="btn btn-outline-success"
                >Продолжить</router-link>
                <router-link v-if="isApproved(item.status)"
                             :to="{ name: 'myProjectRequests.publishing', params: { uuid: item.uuid} }"
                             class="btn btn-success"
                >Опубликовать</router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
  </div>
</template>

<script>
import { DataCollection, Money, PaginatedList, projectStatusLabels } from "@/components/common";
import { ProjectStatus } from "@/utils/api-client";
import { createProjectRequestManager } from "@/utils/di";

export default {
  name: "List",
  components: {
    Money,
    PaginatedList
  },
  data() {
    return {
      collection: new DataCollection()
    };
  },
  methods: {
    async loadData(page, perPage) {
      this.collection.startLoading();
      try {
        const provider = await createProjectRequestManager();
        const collection = await provider.getList(page, perPage);
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    isOnFilling(status) {
      return status === ProjectStatus.REVIEW_APPROVED;
    },
    isOnReview(status) {
      return status === ProjectStatus.REVIEW || status === ProjectStatus.FINAL_REVIEW;
    },
    isApproved(status) {
      return status === ProjectStatus.FINAL_REVIEW_APPROVED;
    },
    isRejected(status) {
      return status === ProjectStatus.REVIEW_REJECTED || status === ProjectStatus.FINAL_REVIEW_REJECTED;
    },
    statusLabel(status) {
      return projectStatusLabels[status] || status;
    }
  }
}
</script>
